<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountNotificationsGrid />
  </MyAccountCheckAuthLoggedIn>
</template>
<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()
const { store } = useDomainState()

useServerSeoMeta(
  useOgMeta(
    url + '/my-account/notifications',
    t('notifications'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('notifications'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/my-account/notifications')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(
        3,
        t('notifications'),
        url + '/my-account/notifications'
      )
    ])
  ]
})
</script>

<template>
  <div class="w-full lg:w-1/2 px-2 mb-2">
    <div
      class="mb-5 flex flex-col bg-bg_alt_color rounded-md shadow p-3 h-full"
    >
      <div class="flex items-center mb-3">
        <div>{{ notification.data.title }}</div>
        <div
          v-if="!isRead"
          class="w-2 h-2 rounded-full bg-green-500 mx-3"
        ></div>
      </div>
      <div class="font-light">{{ notification.data.body }}</div>
      <div class="flex-auto flex-shrink-1"></div>
      <div class="flex text-xs mt-5">
        <button v-if="!isRead" @click="markAsRead">
          {{ t('mark_as_read') }}
        </button>
        <div class="text-e_store-light ltr:ml-auto rtl:mr-auto">
          {{ createdAt }}
        </div>
      </div>
      <button
        v-if="showNotifcation"
        :aria-label="t('details')"
        class="w-full bg-nav_color rounded-md mt-2 py-1 text-text_alt_color text-center"
        @click="goToDetails"
      >
        {{ t('details') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import getAllRoutes from '~/composables/AppApiRoutes'
import { useNotificationStore } from '~~/store/notifications'

const props = defineProps<{ notification: UserNotification }>()

const { t } = useI18n()
const read = ref(false)
const createdAt = new Date(props.notification.created_at).toLocaleDateString()

const isRead = computed(() => {
  if (read.value) {
    return true
  }
  return props.notification.read_at != null
})
const relatedType = props.notification?.data?.english_related_type
const relatedId = props.notification?.data?.related_id
const showNotifcation = computed(() => {
  return (
    (useTypeNotificationIsForClient(props.notification.type) &&
      props.notification.data.id) ||
    useTypeNotificationIsForMoneyMovement(props.notification.type) ||
    (useTypeNotificationIsCustomForAgent(props.notification.type) &&
    relatedId &&
      (relatedType === 'Material' || relatedType === 'Store'))
  )
})

async function markAsRead() {
  try {
    await useBasicFetch(getAllRoutes().notificationsRoutes.makeAsReadByServer, {
      method: 'POST',
      body: { id: props.notification.id }
    })

    const store = useNotificationStore()

    read.value = true
    store.decreaseUnRead()
  } catch (error) {}
}
const router = useRouter()
const localePath = useLocalePath()
function goToDetails() {
  if (!isRead) {
    markAsRead()
  }
  if (useTypeNotificationIsForMoneyMovement(props.notification.type)) {
    return router.push(
      localePath({
        path: '/my-account/money-movements'
      })
    )
  }
  if (
    useTypeNotificationIsForClient(props.notification.type) &&
    props.notification.data.id
  ) {
    return router.push(
      localePath({
        path: `/my-account/orders/${props.notification.data.id}`
      })
    )
  }
  if (
    useTypeNotificationIsCustomForAgent(props.notification.type) &&
    relatedId
  ) {
    if (relatedType === 'Material') {
      return router.push(
        localePath({
          name: 'materials-id',
          params: { id: relatedId }
        })
      )
    }
    if (relatedType === 'Store') {
      return router.push(
        localePath({
          name: 'stores-id',
          params: { id: relatedId }
        })
      )
    }
  }
}
</script>
